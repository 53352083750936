import { inject, Injectable, signal } from '@angular/core';
import { User, UserApiService } from '../../api/user-api.service';
import { waitForSignal } from '../../shared/helpers/wait';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private readonly userApi = inject(UserApiService);

  readonly user = signal<User | null>(null);
  readonly isLoading = signal(false);

  async loadUser(farce = false): Promise<User | null> {
    this.load();
    if (this.user() && !farce) {
      return this.user();
    }
    await waitForSignal(this.isLoading, false);
    return this.user();
  }

  private load() {
    this.isLoading.set(true);
    this.userApi.get().subscribe(user => {
      this.user.set(user);
      this.isLoading.set(false);
    });
  }
}
