import { Signal } from '@angular/core';

export async function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export async function waitForSignal<V>(signal: Signal<V>, value: V) {
  await wait(40);
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      if (signal() === value) {
        clearInterval(interval);
        resolve();
      }
    }, 20);
    setTimeout(() => {
      clearInterval(interval);
      reject();
    }, 60_000);
  });
}
