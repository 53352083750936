import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import dayjs from 'dayjs';
import { ENVIRONMENT } from '../core/tokens/env.token';
import { Api } from './api';
import { fixTZ } from '../shared/helpers/timezone.fix';

interface CheckoutParams {
  stripeToken?: string;
  coupon?: string;
  certificate?: string;
  plan_id?: string;
}

export interface PasswordParameters {
  plainPassword: string;
  plainOldPassword: string;
}

export type User = {
  id: number;
  nickname: string;
  email: string;
  has_access: boolean;
  is_draft: boolean;
  has_trial: boolean;
  trial: string;
};

@Injectable({ providedIn: 'root' })
export class UserApiService extends Api {
  private readonly http = inject(HttpClient);
  private readonly env = inject(ENVIRONMENT);

  buildBaseUrl(input: string): string {
    return this.env.apiOldBaseUrl + '/' + input;
  }

  get(): Observable<User> {
    return this.http.get<User>(this.buildBaseUrl('api/selfuser.json'), {
      params: this.params({
        time_zone: fixTZ(dayjs.tz.guess()),
      }),
    });
  }
}
